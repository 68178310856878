<loader size="md" *ngIf="isLoading" [loading]="isLoading" class="loader"></loader>
<div class="action-container">
  <div class="form">
    <form [formGroup]="marketplaceForm" (ngSubmit)="onSubmit()">
      <div class="field-container">
        <label class="caption1--regular" for="expectedDailyOrders">
          {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.MARKETPLACE_REQUESTED_LABEL') }}
        </label>
        <input id="expectedDailyOrders" formControlName="expectedDailyOrders" type="text" />
        <div
          *ngIf="
            marketplaceForm.get('expectedDailyOrders')?.invalid &&
            marketplaceForm.get('expectedDailyOrders')?.touched
          "
        >
          <small *ngIf="marketplaceForm.get('expectedDailyOrders')?.hasError('required')">
            {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.MARKETPLACE_REQUIRED_FIELD') }}
          </small>
          <small *ngIf="marketplaceForm.get('expectedDailyOrders')?.hasError('pattern')">
            {{
              trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.MARKETPLACE_VALID_NUMBER')
            }}</small
          >
        </div>
      </div>

      <div class="field-container">
        <label class="caption1--regular" for="reason">
          {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.MARKETPLACE_REQUESTED_REASON') }}
        </label>
        <mat-form-field appearance="fill">
          <mat-select class="marketplace-select" formControlName="reason" name="reason">
            <mat-option
              class="marketplace-reason"
              *ngFor="let reason of reasonlist"
              [value]="reason.value"
            >
              {{ trans(reason.name) }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <button class="submit-btn" type="submit" [disabled]="marketplaceForm.invalid">
        {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.MARKETPLACE_SUBMIT_REQUEST') }}
      </button>
    </form>
  </div>
  <div class="fulfilment-rate">
    <div class="gauge-chart">
      <app-order-fulfilment-guage
        *ngIf="!isLoading"
        (acceptanceChances)="onAcceptanceChances($event)"
        [fulfilmentData]="fulfilmentData"
      ></app-order-fulfilment-guage>
    </div>
    <p class="fulfilment-title caption2--regular">
      {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.MARKETPLACE_ORDER_RATE') }}
    </p>
    <hr />
    <div class="probability-container">
      <p class="caption2--regular">
        {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.MARKETPLACE_ORDER_PROBABILITY') }}
      </p>
      <span
        [ngClass]="{
          'error-status-tag': orderAcceptance?.includes('MARKETPLACE_ORDER_LOW'),
          'info-status-tag': orderAcceptance?.includes('MARKETPLACE_ORDER_MEDIUM'),
          'success-status-tag': orderAcceptance?.includes('MARKETPLACE_ORDER_HIGH')
        }"
        class="status-tag caption2--medium"
      >
        {{ trans(orderAcceptance) }}
      </span>
    </div>
    <button class="details-btn caption1--regular" (click)="onViewFulfilmentDetails()">
      {{ trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.MARKETPLACE_ORDER_DETAILS') }}
    </button>
  </div>
</div>
