<loader [loading]="isUploadingImages" size="md"></loader>
<div class="image-section-container">
  <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
    <mat-expansion-panel-header>
      <mat-panel-title>
        <img src="assets/img/gallery-add.svg" />
        <p class="body2--bold title">
          {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.CHECKOUT.UPLOAD_IMAGES_TITLE' | translate }}
        </p>
      </mat-panel-title>
    </mat-expansion-panel-header>
    <p class="mb-0 caption2--medium">
      {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.CHECKOUT.IMAGES_INFO' | translate }}
    </p>
    <div class="product-image-wrapper__uploader">
      <div class="img-preview" *ngFor="let item of mediaGallery; let i = index">
        <img *ngIf="!item.isVideo" [src]="item.url" class="preview" />
        <div *ngIf="item.isVideo" class="video-thumbnail-container">
          <video [src]="item.url" class="preview" type="video/mp4"></video>
          <img src="assets/img/play-circle.svg" class="video-thumbnail" />
        </div>
        <button class="remove-img-btn" (click)="removeImage(i)">
          <img src="assets/img/dukan/remove.svg" alt="delete image" />
        </button>
      </div>
      <label class="upload-btn" *ngIf="imageUrlList.length < 5">
        <input
          class="hidden"
          type="file"
          accept=".jpg, .jpeg, .png, .mp4, video/*"
          multiple
          (change)="uploadFiles($event)"
        />
        <img class="upload-btn__image" src="assets/img/add-icon.svg" alt="upload image" />
      </label>
    </div>
    <p class="mb-0 caption2--medium">
      {{ 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.CHECKOUT.FORMAT_INFO' | translate }}
    </p>
  </mat-expansion-panel>
</div>
