import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
/* eslint-disable-next-line */
import { UserOrderFulfilmentModel } from '@/v3/features/product-lock-request/data/models';

@Component({
  selector: 'app-order-fulfilment-guage',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './order-fulfilment-guage.component.html',
  styleUrls: ['./order-fulfilment-guage.component.scss'],
})
export class OrderFulfilmentGuageComponent implements OnInit {
  @Input() fulfilmentData: UserOrderFulfilmentModel;

  public fulfilmentRate: number;

  public rotationAngle = 0;

  public fillColor = '#B0001C';

  @Output() acceptanceChances: EventEmitter<string> = new EventEmitter();

  ngOnInit() {
    this.fulfilmentRate = this.fulfilmentData?.locking_fulfillment_rate ?? 0;
    this.updateGauge();
  }

  updateGauge(): void {
    this.rotationAngle = this.calculateRotationAngle();
    this.fillColor = this.calculateFillColor();
  }

  calculateRotationAngle(): number {
    return (this.fulfilmentRate / 100) * 180;
  }

  calculateFillColor(): string {
    if (
      this.fulfilmentRate <=
        this.fulfilmentData?.rates_thresholds?.locking_fulfillment_low_threshold ||
      !this.fulfilmentData?.rates_thresholds?.locking_fulfillment_low_threshold
    ) {
      this.acceptanceChances.emit(
        'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.MARKETPLACE_ORDER_LOW',
      );
      return '#B0001C';
    }
    if (
      /* eslint-disable-next-line */
      this.fulfilmentRate > this.fulfilmentData?.rates_thresholds?.locking_fulfillment_low_threshold &&
      this.fulfilmentRate <
        this.fulfilmentData?.rates_thresholds?.locking_fulfillment_high_threshold
    ) {
      this.acceptanceChances.emit(
        'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.MARKETPLACE_ORDER_MEDIUM',
      );
      return '#FFD685';
      /* eslint-disable-next-line */
    } else {
      this.acceptanceChances.emit(
        'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.MARKETPLACE_ORDER_HIGH',
      );
      return '#4CAF50';
    }
  }
}
