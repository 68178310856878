<div class="sc-gauge">
  <div class="sc-background">
    <div
      class="sc-percentage"
      [ngStyle]="{
        transform: 'rotate(' + rotationAngle + 'deg)',
        'background-color': fillColor
      }"
    ></div>
    <div class="sc-mask"></div>
    <span class="sc-value">{{ fulfilmentRate | number : '1.2-2' }}%</span>
  </div>
</div>
