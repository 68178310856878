import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { trans } from '@mongez/localization';
import { TranslateModule } from '@ngx-translate/core';
import { UploadImageUseCase } from 'app/core/usecases/utilities/upload-image.usecase';
import { LoaderComponent } from 'app/presentation/shared/components/loader/loader.component';
import { ToastrService } from 'ngx-toastr';
import { finalize, forkJoin } from 'rxjs';

export const isVideoFromUrl = (url: string): boolean => url.endsWith('.mp4');

@Component({
  selector: 'app-product-checkout-images',
  standalone: true,
  templateUrl: './product-checkout-images.component.html',
  styleUrls: ['./product-checkout-images.component.scss'],
  imports: [CommonModule, MatExpansionModule, TranslateModule, LoaderComponent],
})
export class ProductCheckoutImagesComponent {
  @Input() imageUrlList: string[];

  @Output() deletedImageIndex = new EventEmitter<string[]>();

  @Output() uploadedImagesURL = new EventEmitter<string[]>();

  panelOpenState = false;

  selectedFiles?: FileList;

  isUploadingImages = false;

  public mediaGallery: { url: string }[];

  private _toasterService = inject(ToastrService);

  private _uploadImageUseCase = inject(UploadImageUseCase);

  ngOnChanges(): void {
    this.checkForImages();
  }

  checkForImages(): void {
    this.mediaGallery = this.imageUrlList.map((item: any) => ({
      url: item,
      isVideo: isVideoFromUrl(item),
    }));
  }

  getFormDataFromFiles(files: any) {
    const formDataArray = [];
    // Uploaded file should be less than 1MB if image or 8mb if video
    const maxSize = files[0].type.includes('video') ? 8 * 1024 * 1024 : 1 * 1024 * 1024;
    if (files && files[0]) {
      const numberOfFiles = files.length;
      for (let i = 0; i < numberOfFiles; i++) {
        const formData = new FormData();
        if (files[i].size < maxSize) {
          formData.append('image', files[i]);
          formDataArray.push(formData);
        } else {
          this._toasterService.error(
            trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.CHECKOUT.IMAGE_SIZE_ERROR'),
          );
        }
      }
    }
    return formDataArray;
  }

  uploadFiles(event: any) {
    this.selectedFiles = event.target.files;
    this.isUploadingImages = true;
    const formDataArray = this.getFormDataFromFiles(this.selectedFiles);
    forkJoin({
      ...formDataArray.map((file) => this._uploadImageUseCase.execute(file)),
    })
      .pipe(
        finalize(() => {
          this.isUploadingImages = false;
        }),
      )
      .subscribe({
        next: (data) => {
          const urls = Object.values(data).map((item: any) => item.url);
          this.uploadedImagesURL.emit([...this.mediaGallery.map((item) => item.url), ...urls]);
        },
        error: (err) => {
          this._toasterService.error(
            trans('PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.CHECKOUT.ERROR_OCCURED'),
          );
        },
      });
  }

  removeImage(deletedIndex: number): void {
    this.deletedImageIndex.emit(this.imageUrlList.filter((url, idx) => idx !== deletedIndex));
  }
}
