import { ProductLockRequestUseCaseResolver } from '@/v3/features/product-lock-request/domain/resolvers/product-lock-request-use-case.resolver';
import { CommonModule } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { ProductBasicInfo } from 'app/core/domain/products/product-detail.model';
import { LocalizedComponent } from 'app/presentation/base/localized.component';
import { OrderFulfilmentGuageComponent } from '../order-fulfilment-gauge/order-fulfilment-guage.component';
import { ProductFulfilmentDetailsPopupComponent } from '../product-fulfilment-details-popup/product-fulfilment-details-popup.component';
import { ProductLockRequestSuccessPopupComponent } from '../product-lock-request-success-popup/product-lock-request-success-popup.component';
/* eslint-disable */
import { mixPanelEvent } from '@/v3/features/activities/data/mixpanel';
import { ProductLockRequestCreateModel, UserOrderFulfilmentModel } from '@/v3/features/product-lock-request/data/models';
import { lockingRequestTrackEvents } from '@/v3/features/product-lock-request/data/constants';
import { catchError, parseError } from '@/v3/presentation/shared/error';
import { LoaderComponent } from 'app/presentation/shared/components/loader/loader.component';
import { finalize } from 'rxjs/operators';
/* eslint-enable */

@Component({
  selector: 'app-product-action-marketplace',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatSelectModule,
    OrderFulfilmentGuageComponent,
    LoaderComponent,
  ],
  templateUrl: './product-action-marketplace.component.html',
  styleUrls: ['./product-action-marketplace.component.scss'],
})
export class ProductActionMarketplaceComponent extends LocalizedComponent implements OnInit {
  constructor(private _formBuilder: FormBuilder, private _matDialog: MatDialog) {
    super();
  }

  @Input() isMarketplaceRequestTypeUpdate: boolean;

  @Input() basicInfo: ProductBasicInfo;

  public marketplaceForm: FormGroup;

  public isLoading = false;

  public fulfilmentData: UserOrderFulfilmentModel;

  public orderAcceptance: string;

  public reasonlist = [
    {
      name: 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.MARKETPLACE_TESTING_VALUE',
      value: 'PRODUCT_TESTING',
    },
    {
      name: 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.MARKETPLACE_SCALING_VALUE',
      value: 'PRODUCT_SCALING',
    },
    { name: 'PRODUCTS_DETAIL_PAGE.PRODUCT_ACTIONS.ACTION.MARKETPLACE_OTHER_VALUE', value: 'OTHER' },
  ];

  ngOnInit(): void {
    this._initializeForm();
    this.getUserFulfilmentRate();
  }

  getUserFulfilmentRate(): void {
    this.isLoading = true;
    ProductLockRequestUseCaseResolver.getUserFulfilmentRate()
      .then((result) => {
        this.fulfilmentData = result;
        this.isLoading = false;
      })
      .catch((err) => {
        this.isLoading = false;
        catchError(err);
      });
  }

  private _initializeForm(): void {
    this.marketplaceForm = this._formBuilder.group({
      expectedDailyOrders: [null, [Validators.required, Validators.pattern('^[0-9]*$')]],
      reason: ['', Validators.required],
    });
  }

  public onViewFulfilmentDetails(): void {
    mixPanelEvent(lockingRequestTrackEvents.FULFILMENT_RATE_DETAILS);
    this._matDialog.open(ProductFulfilmentDetailsPopupComponent, {
      width: '536px',
      direction: this.isLTR ? 'ltr' : 'rtl',
    });
  }

  onSubmit(): void {
    this.isLoading = true;
    if (this.marketplaceForm.valid) {
      const formData = this.marketplaceForm.value;
      const data: ProductLockRequestCreateModel = {
        sku: this.basicInfo.productId,
        requestReason: formData.reason,
        expectedDailyOrders: +formData.expectedDailyOrders,
        requestType: this.isMarketplaceRequestTypeUpdate ? 'LOCK_EXTENSION' : 'LOCK_CREATION',
      };
      ProductLockRequestUseCaseResolver.productLockRequestCreate(data)
        .then((result) => {
          this.trackRequest(data, 'success');
          this.isLoading = false;
          this._matDialog.open(ProductLockRequestSuccessPopupComponent, {
            width: '536px',
            direction: this.isLTR ? 'ltr' : 'rtl',
          });
          this.marketplaceForm.reset();
        })
        .catch((error) => {
          this.isLoading = false;
          this.trackRequest(data, 'error', error);
          catchError(error);
        });
    }
  }

  trackRequest(data: any, status: 'success' | 'error', err?: any): void {
    mixPanelEvent(lockingRequestTrackEvents.REQUEST_LOCKING, {
      payload: data,
      status,
      errorObject: err,
      errorMessage: parseError(err),
    });
  }

  onAcceptanceChances(event: string): void {
    this.orderAcceptance = event;
  }
}
